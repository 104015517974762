import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        stepConnector: {
            marginLeft: '15px',
            borderLeft: `1px solid #A0B3B8`,
            width: '0px',
            minHeight: '26px',
        },
        icon: {
            marginRight: '15px',
            marginLeft: '1px',
            fontSize: '29px',
            color: theme.palette.primary.main,
        },
        checkIcon: { marginRight: '15px', marginLeft: '1px', fontSize: '29px', color: theme.palette.success.main },
        greyIcon: { marginRight: '15px', marginLeft: '1px', fontSize: '29px', color: theme.palette.grey[500] },
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
            justifyContent: 'start',
            alignItems: 'start',
            marginLeft: theme.spacing(8),
            marginBottom: theme.spacing(4),
        },
    };
});
