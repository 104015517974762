import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CollapsableCard } from '@components/CollapsableCard/CollapsableCard';
import { useTranslation } from 'react-i18next';
import { AlarmingDataButton } from '@features/alarmingData/AlarmingDataButton/AlarmingDataButton';
import { useGetFarmerProfileParamsFromUrl } from '@features/farmer/profile/hooks/useGetFarmerProfileParamsFromUrl';
import { RenewalStatus } from '@features/farmer/profile/farmSeasonStatus/RenewalStatus/RenewalStatus';
import { InitialSetupStatus } from '@features/farmer/profile/farmSeasonStatus/InitialSetupStatus/InitialSetupStatus';
import { OperationalDataStatus } from '@features/farmer/profile/farmSeasonStatus/OperationalDataStatus/OperationalDataStatus';
import { DataValidationStatus } from '@features/farmer/profile/farmSeasonStatus/DataValidationStatus/DataValidationStatus';
import { ResultStatus } from '@features/farmer/profile/farmSeasonStatus/ResultStatus/ResultStatus';
import { useFarmSeasonSteps } from '@features/farmer/profile/farmSeasonStatus/useFarmSeasonSteps';
import { CARBON_RESULT_MODEL, FARMER_STATUS } from '@utils/constants';
export const FarmSeasonCollapsable = ({ farmSeason, previousFarmSeason, isExpanded = false, profile, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { t } = useTranslation();
    const { userId, invitationId } = useGetFarmerProfileParamsFromUrl();
    const id = userId || invitationId;
    const carbonModel = (_c = (_b = (_a = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _a === void 0 ? void 0 : _a.farm) === null || _b === void 0 ? void 0 : _b.carbon_result_model) !== null && _c !== void 0 ? _c : CARBON_RESULT_MODEL.CFT;
    const farmerStatus = (_d = profile === null || profile === void 0 ? void 0 : profile.status) !== null && _d !== void 0 ? _d : FARMER_STATUS.CREATED;
    const isBaselineYear = (farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.baseline_farm_season_id) === null;
    const isPartOfCarbonProgramme = !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.carbon_programme_year);
    const { currentStep, maxSteps } = useFarmSeasonSteps({ farmSeason, previousFarmSeason, carbonModel });
    const collapsableCardHeader = isBaselineYear
        ? t('labels.baseline-season-years', { years: `${(_e = farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.season) === null || _e === void 0 ? void 0 : _e.harvest_year}` })
        : t('labels.season-count-years', {
            count: (_f = farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.carbon_programme_year) === null || _f === void 0 ? void 0 : _f.year,
            years: `${(_g = farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.season) === null || _g === void 0 ? void 0 : _g.harvest_year}`,
        });
    const statusProps = {
        farmSeason,
        previousFarmSeason,
        carbonModel,
        farmerStatus,
    };
    if (!isPartOfCarbonProgramme)
        return null;
    return (_jsxs(CollapsableCard, Object.assign({ isExpanded: isExpanded, header: collapsableCardHeader, action: !!id && _jsx(AlarmingDataButton, { userOrInvitationId: id, farmSeasonId: Number(farmSeason.id) }), currentStep: currentStep, maxSteps: maxSteps }, { children: [_jsx(RenewalStatus, Object.assign({}, statusProps)), _jsx(InitialSetupStatus, Object.assign({}, statusProps)), _jsx(OperationalDataStatus, Object.assign({}, statusProps, { profile: profile })), _jsx(DataValidationStatus, Object.assign({}, statusProps)), _jsx(ResultStatus, Object.assign({}, statusProps, { profile: profile }))] })));
};
