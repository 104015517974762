import { api } from '@services/api.service';
export const resultsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        changeResultPublication: builder.mutation({
            query: (body) => ({
                url: `/private/results/publication`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Farmer', 'Farmers'],
        }),
        generateResultsV3: builder.mutation({
            query: ({ farmSeasonId }) => ({
                url: `${process.env.FARMER_PORTAL_API}/v3/farm-seasons/${farmSeasonId}/results/generate`,
                method: 'POST',
            }),
            invalidatesTags: ['Farmer', 'Farmers'],
        }),
    }),
});
export const { useChangeResultPublicationMutation, useGenerateResultsV3Mutation } = resultsApi;
